import { createSlice } from '@reduxjs/toolkit'

import {
  fetchStudios,
  fetchStudioById,
  fetchStudioMemberships,
  fetchStudioClasses,
  fetchStudioClassSessions,
  fetchStudioContacts,
  fetchStudioEvents,
  fetchStudioContactUrls,
} from 'store/actions/services.actions'

const studiosSlice = createSlice({
  name: 'studios',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    studio: null,
    studios: {
      isLoading: false,
      status: null,
      errors: null,
    },
    studioContactUrls: {
      isLoading: false,
      status: null,
      errors: null,
    },
    memberships: {
      isLoading: false,
      status: null,
      errors: null,
    },
    contacts: {
      isLoading: false,
      status: null,
      errors: null,
    },
    classes: {
      isLoading: false,
      status: null,
      errors: null,
    },
    classSessions: {
      isLoading: false,
      status: null,
      errors: null,
    },
    events: {
      isLoading: false,
      status: null,
      errors: null,
    },
  },
  reducers: {
    clearStudio: (state) => ({ ...state, studio: null }),
    clearStudios: (state) => ({ ...state, studios: { isLoading: false, status: null, errors: null } }),
    clearMemberships: (state) => ({ ...state, memberships: { isLoading: false, status: null, errors: null } }),
    clearClasses: (state) => ({ ...state, classes: { isLoading: false, status: null, errors: null } }),
    clearClasseSessions: (state) => ({ ...state, classSessions: { isLoading: false, status: null, errors: null } }),
    clearContacts: (state) => ({ ...state, contacts: { isLoading: false, status: null, errors: null } }),
    clearEvents: (state) => ({ ...state, events: { isLoading: false, status: null, errors: null } }),
  },
  extraReducers: {
    /**
     * Pendings
     */
    [`${fetchStudios.pending}`]: (state, action) => ({
      ...state,
      studios: { ...state.studios, isLoading: true, errors: null },
    }),
    [`${fetchStudioById.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchStudioMemberships.pending}`]: (state, action) => ({
      ...state,
      memberships: { ...state.memberships, isLoading: true, errors: null },
    }),
    [`${fetchStudioClasses.pending}`]: (state, action) => ({
      ...state,
      classes: { ...state.classes, isLoading: true, errors: null },
    }),
    [`${fetchStudioClassSessions.pending}`]: (state, action) => ({
      ...state,
      classSessions: { ...state.classes, isLoading: true, errors: null },
    }),
    [`${fetchStudioContacts.pending}`]: (state, action) => ({
      ...state,
      contacts: { ...state.contacts, isLoading: true, errors: null },
    }),
    [`${fetchStudioEvents.pending}`]: (state, action) => ({
      ...state,
      events: { ...state.events, isLoading: true, errors: null },
    }),
    [`${fetchStudioContactUrls.pending}`]: (state, action) => ({
      ...state,
      studioContactUrls: { ...state.studioContactUrls, isLoading: true, errors: null },
    }),

    /**
     * Rejected
     */
    [`${fetchStudios.rejected}`]: (state, action) => ({
      ...state,
      studios: { ...state.studios, isLoading: false, errors: action?.error?.message },
    }),
    [`${fetchStudioById.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchStudioMemberships.rejected}`]: (state, action) => ({
      ...state,
      memberships: { ...state.memberships, isLoading: false, errors: action?.error?.message },
    }),
    [`${fetchStudioClasses.rejected}`]: (state, action) => ({
      ...state,
      classes: { ...state.classes, isLoading: false, errors: action?.error?.message },
    }),
    [`${fetchStudioClassSessions.rejected}`]: (state, action) => ({
      ...state,
      classSessions: { ...state.classes, isLoading: false, errors: action?.error?.message },
    }),
    [`${fetchStudioContacts.rejected}`]: (state, action) => ({
      ...state,
      contacts: { ...state.contacts, isLoading: false, errors: action?.error?.message },
    }),
    [`${fetchStudioEvents.rejected}`]: (state, action) => ({
      ...state,
      events: { ...state.events, isLoading: false, errors: action?.error?.message },
    }),
    [`${fetchStudioContactUrls.rejected}`]: (state, action) => ({
      ...state,
      studioContactUrls: { ...state.studioContactUrls, isLoading: false, errors: action?.error?.message },
    }),

    /**
     * Fulfilled
     */
    [`${fetchStudios.fulfilled}`]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        studios: { ...state.studios, isLoading: false, errors: null, ...action.payload },
      }
    },
    [`${fetchStudioById.fulfilled}`]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        studio: {
          ...action.payload,
          submit_usage: action.payload?.studio_group?.submit_usage ? true : action.payload?.submit_usage,
        },
      }
    },
    [`${fetchStudioMemberships.fulfilled}`]: (state, action) => {
      return { ...state, memberships: { ...state.memberships, isLoading: false, errors: null, ...action.payload } }
    },
    [`${fetchStudioClasses.fulfilled}`]: (state, action) => {
      return { ...state, classes: { ...state.classes, isLoading: false, errors: null, ...action.payload } }
    },
    [`${fetchStudioClassSessions.fulfilled}`]: (state, action) => {
      return { ...state, classSessions: { ...state.classes, isLoading: false, errors: null, ...action.payload } }
    },
    [`${fetchStudioContacts.fulfilled}`]: (state, action) => {
      return { ...state, contacts: { ...state.contacts, isLoading: false, errors: null, ...action.payload } }
    },
    [`${fetchStudioEvents.fulfilled}`]: (state, action) => {
      return { ...state, events: { ...state.events, isLoading: false, errors: null, ...action.payload } }
    },
    [`${fetchStudioContactUrls.fulfilled}`]: (state, action) => {
      return {
        ...state,
        studioContactUrls: { ...state.studioContactUrls, isLoading: false, errors: null, ...action.payload },
      }
    },
  },
})

export const studiosActions = studiosSlice.actions

export default studiosSlice.reducer
