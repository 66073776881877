export const PromoCodeEncodingMethods = [
  { value: 1, label: 'Redeemable User' },
  { value: 2, label: 'Redeemable Sponsor' },
  { value: 3, label: 'Referrer' },
]

export const PromotionTypes = [
  { value: 1, label: 'Free Credits' },
  { value: 2, label: 'Discount' },
]

export const BillingPartyTypes = [
  { value: 1, label: 'Redeeming User' },
  { value: 2, label: 'Redeeming Users Sponsor' },
  { value: 3, label: 'Specific Sponsor' },
  { value: 4, label: 'Referrer' },
]

export const RedemptionMethods = [
  { value: 0, label: 'Standalone' },
  { value: 1, label: 'Reservation' },
  { value: 2, label: 'Purchase' },
]

export const ReferrerTypes = [
  { value: 0, label: 'None' },
  { value: 1, label: 'User' },
  { value: 2, label: 'Sponsor' },
  { value: 3, label: 'Studio' },
  { value: 4, label: 'Other' },
]

export const PromoCodeTransactionTypeOptions = [
  { value: 4, label: 'Up Front Billing' },
  { value: 24, label: 'On Consumption Billing' },
]

export const StudioContactTypes = [
  // { id: 0, name: 'No Communications sent to this contact' },
  { id: 1, name: 'Membership Receipt' },
  { id: 2, name: 'Class Reservation Receipt' },
  { id: 3, name: 'Reimbursement Summary' },
  // { id: 4, name: 'Report for membership reimbursements' },
  { id: 5, name: 'Membership Status' },
]

export const InvoiceTypes = [
  { id: 1, name: 'PmPm Plus Usage' },
  { id: 2, name: 'Legacy Disbursement' },
  { id: 3, name: 'Unlimited' },
  { id: 4, name: 'PmPm Fully Insured' },
  { id: 5, name: 'Digital' },
]

export const CompanyTypes = [
  { id: 1, name: 'Direct Employer' },
  { id: 2, name: 'Health Plan' },
  { id: 3, name: 'Health Plan Employer' },
  { id: 4, name: 'Channel Partner' },
  { id: 5, name: 'Channel Partner Employer' },
]
