import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import 'i18n'
import reportWebVitals from 'reportWebVitals'
import App from 'App'

import { LoadingScreen } from 'components'

import 'styles/main.scss'
import 'styles/offlineRegistration.scss'

ReactDOM.render(
  <Suspense fallback={<LoadingScreen />}>
    <App />
  </Suspense>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
