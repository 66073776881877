import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { fetchTags } from 'store/actions/services.actions'
import { AutoCompleteBox } from 'helpers/forms'

export const TagsAutocompleteBox = React.memo(
  ({ user, control, name, label, errors, required, onValueChange, ...props }) => {
    const { isLoading, tags } = useSelector((state) => state.enums)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)

    const options = React.useMemo(() => (isEmpty(tags) ? [] : tags.filter((item) => !isEmpty(item?.name))), [tags])

    const handleOpen = () => {
      isEmpty(tags) && dispatch(fetchTags())
      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }

    return (
      <AutoCompleteBox
        control={control}
        errors={errors}
        name={name}
        label={label || t('common.form_labels.tags')}
        isLoading={isLoading}
        required={required}
        options={options}
        onValueChange={onValueChange}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        multiple
      />
    )
  }
)
