import { LogLevel } from '@azure/msal-browser'

import { AUTH_CLIENT_ID, API_CLIENT_ID } from 'constants/globals'

const { origin } = window.location

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: AUTH_CLIENT_ID,
    redirectUri: origin,
    postLogoutRedirectUri: `${origin}/login`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        /*eslint-disable */
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            // console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            /*eslint-enable */
            return
          default:
            return
        }
      },
    },
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [`api://${API_CLIENT_ID}/user_impersonation`],
}
