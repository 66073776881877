import { createSlice } from '@reduxjs/toolkit'

import { fetchConsumerPlans } from 'store/actions/services.actions'

const consumerSlice = createSlice({
  name: 'consumer',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    plans: [],
  },
  reducers: {
    clearPlans: (state) => ({
      ...state,
      plans: {
        isLoading: false,
        status: null,
        errors: null,
      },
    }),
  },
  extraReducers: {
    [`${fetchConsumerPlans.pending}`]: (state, action) => ({
      ...state,
      plans: { ...state.plans, isLoading: true, errors: null },
    }),

    [`${fetchConsumerPlans.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),

    [`${fetchConsumerPlans.fulfilled}`]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        plans: { ...state.plans, isLoading: false, status: null, errors: null, ...action.payload },
      }
    },
  },
})

export const consumerActions = consumerSlice.actions

export default consumerSlice.reducer
