import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isEmpty, debounce } from 'lodash'

import { fetchBudgets } from 'store/actions/services.actions'
import { budgetActions } from 'store/reducers/budgets.reducer'
import { AutoCompleteBox } from 'helpers/forms'
import { Box } from '@material-ui/core'

export const BudgetsAutocompleteBox = React.memo(({ name, control, errors, onValueChange }) => {
  const {
    budgets: { isLoading },
    budgets,
  } = useSelector((state) => state.services.budgets)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const isBudgetsEmpty = isEmpty(budgets?.results)

  const options = React.useMemo(() => (isBudgetsEmpty ? [] : budgets?.results), [budgets, isBudgetsEmpty])

  useEffect(() => {
    if (isBudgetsEmpty) {
      dispatch(fetchBudgets())
    }
  }, [isBudgetsEmpty, dispatch])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    dispatch(budgetActions.clearBudgets())
  }

  const handleResultsChange = debounce((results, value) => {
    results.length < 2 && !isLoading && dispatch(fetchBudgets({ searchq: value }))
  }, 300)

  return (
    <Box mb={2}>
      <AutoCompleteBox
        control={control}
        errors={errors}
        name={name || 'budget'}
        label={t('common.form_labels.budget')}
        required
        options={options.map((option) => ({
          name: option?.department,
          id: option.id,
        }))}
        onValueChange={onValueChange}
        onResultsChange={handleResultsChange}
        onOpen={handleOpen}
        onClose={handleClose}
        isLoading={isLoading}
        open={open}
      />
    </Box>
  )
})
