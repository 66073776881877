import { createSlice } from '@reduxjs/toolkit'

import { fetchFeatureFlags } from 'store/actions/services.actions'

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    featureFlag: null,
    featureFlags: {},
  },
  reducers: {
    clearFeatureFlags: (state) => ({ ...state, featureFlag: null }),
  },
  extraReducers: {
    [`${fetchFeatureFlags.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchFeatureFlags.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchFeatureFlags.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, featureFlags: { ...action.payload } }
    },
  },
})

export const featureFlagsActions = featureFlagsSlice.actions

export default featureFlagsSlice.reducer
