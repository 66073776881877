import { useMsal } from '@azure/msal-react'
import { Avatar, Box, ButtonBase, Hidden, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}))

const Account = () => {
  const { instance } = useMsal()
  const { name } = useSelector((state) => state.auth.account)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const ref = useRef(null)
  const { t } = useTranslation()

  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      instance.logoutRedirect()
    } catch (err) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Hidden smDown>
          <Typography variant="h6" color="textPrimary">
            {name}
          </Typography>
        </Hidden>
        <Avatar alt="User" className={classes.avatar} src={null} />
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>{t('actions.logout')}</MenuItem>
      </Menu>
    </>
  )
}

export default Account
