import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Container, Paper, makeStyles, useTheme } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ScaleLoader } from 'react-spinners'

const useStyles = makeStyles((theme) => ({
  page: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  loader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '80vh',
    justifyContent: 'center',
  },
}))

const Page = ({ title, errors, showLoader, children, ...props }) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.page} {...props}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {errors && (
        <Box mb={3}>
          <Container maxWidth={false}>
            <Paper>
              <Alert severity="error">{errors}</Alert>
            </Paper>
          </Container>
        </Box>
      )}
      {showLoader ? (
        <div className={classes.loader}>
          <ScaleLoader height={60} width={10} radius={10} margin={6} color={theme.palette.primary.main} />
        </div>
      ) : (
        children
      )}
    </div>
  )
}

export default Page
