import { format } from 'date-fns'

const DATE_TIME_FORMAT = 'MM/dd/yyyy (h:mm aa)'
const DATE_TIME_SECONDS_FORMAT = 'MM/dd/yyyy (h:mm:ss aa)'

const getFormattedDateTime = (iso) => {
  return iso ? format(new Date(iso), DATE_TIME_FORMAT) : '—'
}

export const getFormattedDateSecondsTime = (iso) => {
  return iso ? format(new Date(iso), DATE_TIME_SECONDS_FORMAT) : '—'
}

export default getFormattedDateTime
