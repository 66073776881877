import { createSlice } from '@reduxjs/toolkit'

import { fetchStoreItems } from 'store/actions/services.actions'

const storeSlice = createSlice({
  name: 'store',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    storeItems: {},
  },
  reducers: {
    clearStoreItems: (state) => ({ ...state, storeItems: {} }),
  },
  extraReducers: {
    [`${fetchStoreItems.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),

    [`${fetchStoreItems.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),

    [`${fetchStoreItems.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, storeItems: { ...action.payload } }
    },
  },
})

export const storeActions = storeSlice.actions

export default storeSlice.reducer
