import { createSlice } from '@reduxjs/toolkit'

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    breakpoint: null,
  },
  reducers: {
    setBreakpoint(state, action) {
      const { breakpoint } = action.payload
      state.breakpoint = breakpoint
    },
  },
})

export const { setBreakpoint } = globalSlice.actions

export default globalSlice.reducer
