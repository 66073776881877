import { createSlice } from '@reduxjs/toolkit'

import { fetchBrands, fetchBrandById } from 'store/actions/services.actions'

const brandsSlice = createSlice({
  name: 'brands',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    brand: null,
    brands: {
      isLoading: false,
      status: null,
      errors: null,
    },
  },
  reducers: {
    clearBrand: (state) => ({ ...state, brand: null }),
    clearBrands: (state) => ({
      ...state,
      brands: {
        isLoading: false,
        status: null,
        errors: null,
      },
    }),
  },
  extraReducers: {
    [`${fetchBrands.pending}`]: (state, action) => ({ ...state, brands: { isLoading: true, errors: null } }),
    [`${fetchBrandById.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),

    [`${fetchBrands.rejected}`]: (state, action) => ({
      ...state,
      brands: { ...state.brands, isLoading: false, errors: action?.error?.message },
    }),
    [`${fetchBrandById.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),

    [`${fetchBrands.fulfilled}`]: (state, action) => {
      return { ...state, brands: { ...state.brands, isLoading: false, errors: null, ...action.payload } }
    },
    [`${fetchBrandById.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, errors: null, brand: { ...action.payload } }
    },
  },
})

export const brandsActions = brandsSlice.actions

export default brandsSlice.reducer
