import React, { useRef, useState } from 'react'
import { remove } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Badge, Box, Button, Checkbox, FormGroup, FormControlLabel, Paper, Popover } from '@material-ui/core'
import { IoFilterSharp as FilterIcon } from 'react-icons/io5'

const DropdownFilters = ({ filters, selectedFilters, onApply, ...props }) => {
  const filtersButtonRef = useRef()
  const { t } = useTranslation()

  const [showFiltersMenu, setShowFiltersMenu] = useState(false)

  const handleToggleFiltersMenu = (e) => {
    setShowFiltersMenu(!showFiltersMenu)
  }

  const handleHideFiltersMenu = (e) => {
    setShowFiltersMenu(false)
  }

  const handleChange = (e) => {
    e.target.checked
      ? onApply([...selectedFilters, e.target.name])
      : onApply(remove([...selectedFilters], (id) => id && id !== e.target.name))
  }

  return (
    <>
      <Badge badgeContent={selectedFilters && selectedFilters?.length} color="primary">
        <Button
          startIcon={<FilterIcon />}
          ref={filtersButtonRef}
          onClick={handleToggleFiltersMenu}
          aria-controls={showFiltersMenu ? 'filters-menu' : undefined}
          aria-haspopup="true"
        >
          {t('actions.filters')}
        </Button>
      </Badge>

      <Popover
        id="filters-menu"
        open={showFiltersMenu}
        onClose={handleHideFiltersMenu}
        anchorEl={filtersButtonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper>
          <Box p={2}>
            <FormGroup>
              {filters.map((filter) => (
                <FormControlLabel
                  key={filter.id}
                  name={filter.id}
                  label={filter.label}
                  control={
                    <Checkbox
                      onChange={handleChange}
                      checked={selectedFilters && selectedFilters?.includes(filter.id)}
                    />
                  }
                />
              ))}
            </FormGroup>
          </Box>
        </Paper>
      </Popover>
    </>
  )
}

export default React.memo(DropdownFilters)
