import { createSlice } from '@reduxjs/toolkit'

import { fetchBudgets, fetchBudgetById } from 'store/actions/services.actions'

const budgetsSlice = createSlice({
  name: 'budgets',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    budget: null,
    budgets: {},
  },
  reducers: {
    clearBudget: (state) => ({ ...state, budget: null }),
    clearBudgets: (state) => ({
      ...state,
      budgets: {
        isLoading: false,
        status: null,
        errors: null,
      },
    }),
  },
  extraReducers: {
    [`${fetchBudgets.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchBudgetById.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),

    [`${fetchBudgets.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchBudgetById.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),

    [`${fetchBudgets.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, budgets: { ...action.payload } }
    },
    [`${fetchBudgetById.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, budget: { ...action.payload } }
    },
  },
})

export const budgetActions = budgetsSlice.actions

export default budgetsSlice.reducer
