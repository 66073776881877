import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const SortHeader = ({ columns, order, sortBy, onSort, hasActions }) => {
  const { t } = useTranslation()
  const createSortHandler = (prop) => (event) => {
    onSort(event, prop)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align || 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={sortBy === column.id ? order : false}
          >
            {column.sortable ? (
              <TableSortLabel
                active={sortBy === column.id}
                direction={sortBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
        {hasActions && <TableCell align="right">{t('common.actions')}</TableCell>}
      </TableRow>
    </TableHead>
  )
}

export default SortHeader
