import { createAsyncThunk } from '@reduxjs/toolkit'
import { values, isNil } from 'lodash'
import { adminApi } from 'services/admin.api'

// import { mockApi } from 'services/mock.api'

/**
 * Brands Actions
 */
export const fetchBrands = createAsyncThunk('@@fetch/fetchBrands', async () => {
  const response = await adminApi.getBrands()
  const { data } = response
  return data
})

export const fetchBrandById = createAsyncThunk('@@fetch/fetchBrandById', async (id) => {
  const response = await adminApi.getBrandById(id)
  const { data } = response
  return data
})

export const postBrand = createAsyncThunk('@@post/postBrand', async (params = {}) => {
  const response = await adminApi.postBrand(params)
  const { data } = response
  return data
})

export const putBrandById = createAsyncThunk('@@put/putBrandById', async ({ id, params = {} }) => {
  const response = await adminApi.putBrandById(id, params)
  const { data } = response
  return data
})

export const deleteBrandById = createAsyncThunk('@@delete/deleteBrandById', async (id) => {
  const response = await adminApi.deleteBrandById(id)
  const { data } = response
  return data
})

export const fetchFeatureFlags = createAsyncThunk('@@fetch/fetchFeatureFlags', async (params) => {
  const response = await adminApi.getFeatureFlags(params)
  const { data } = response
  const parsed = values(data)
  return { results: parsed, count: parsed.length }
})

export const putFeatureFlagById = createAsyncThunk('@@put/putFeatureFlagById', async ({ params = {} }) => {
  const response = await adminApi.putFeatureFlagById(params)
  const { data } = response
  return data
})

/**
 * Regions Actions
 */
export const fetchRegions = createAsyncThunk('@@fetch/fetchRegions', async (params) => {
  const response = await adminApi.getRegions(params)
  const { data } = response
  return data
})

export const fetchRegionById = createAsyncThunk('@@fetch/fetchRegionById', async (id) => {
  const response = await adminApi.getRegionById(id)
  const { data } = response
  return data
})

export const postRegion = createAsyncThunk('@@post/postRegion', async (params = {}) => {
  const response = await adminApi.postRegion(params)
  const { data } = response
  return data
})

export const putRegionById = createAsyncThunk('@@put/putRegionById', async ({ id, params = {} }) => {
  const response = await adminApi.putRegionById(id, params)
  const { data } = response
  return data
})

export const deleteRegionById = createAsyncThunk('@@delete/putRegionById', async (id) => {
  const response = await adminApi.deleteRegionById(id)
  const { data } = response
  return data
})

/**
 * Users Actions
 */
export const fetchUserById = createAsyncThunk('@@fetch/fetchUserById', async (id) => {
  const response = await adminApi.getUserById(id)
  const { data } = response
  return data
})

export const putUserById = createAsyncThunk('@@put/putUserById', async ({ user_id, params }) => {
  await adminApi.putUserById(user_id, params)
  const response = await adminApi.getUserById(user_id)
  const { data } = response
  return data
})

export const fetchUserMemberships = createAsyncThunk('@@fetch/fetchUserMemberships', async ({ user_id, params }) => {
  const response = await adminApi.getUserMemberships(user_id, params)
  const { data } = response
  return data
})

export const cancelUserMembership = createAsyncThunk(
  '@@put/cancelUserMembership',
  async ({ user_id, membership_id }) => {
    const response = await adminApi.cancelUserMembership(user_id, membership_id)
    const { data } = response
    return data
  }
)

export const reactivateUserMembership = createAsyncThunk('@@post/reactivateUserMembership', async ({ params }) => {
  const response = await adminApi.reactivateUserMembership(params)
  const { data } = response
  return data
})

export const fetchUserSponsors = createAsyncThunk('@@fetch/fetchUserSponsors', async ({ user_id, params }) => {
  const response = await adminApi.getUserSponsors(user_id, params)
  const { data } = response
  return data
})

export const fetchUserTransactions = createAsyncThunk('@@fetch/fetchUserTransactions', async (params) => {
  const response = await adminApi.getTransactions(params)
  const { data } = response
  return data
})

export const postUserMembership = createAsyncThunk(
  '@@post/postUserMembership',
  async ({ user_id, membership_plan_id, estimated_amount }) => {
    const body = {
      user_id,
      membership_plan_id,
    }
    if (!isNil(estimated_amount)) {
      body.estimated_amount = +estimated_amount
    }
    const response = await adminApi.postUserMembership(user_id, body)
    const { data } = response
    return data
  }
)

export const postUserPurchase = createAsyncThunk('@@post/postUserPurchase', async ({ user_id, params }) => {
  try {
    const response = await adminApi.postUserPurchase(user_id, params)
    const { data } = response
    return data
  } catch (error) {
    throw new Error(error?.response?.data?.Message || error)
  }
})

export const postUserReservation = createAsyncThunk('@@post/postUserReservation', async ({ user_id, params }) => {
  const response = await adminApi.postUserReservation(user_id, params)
  const { data } = response
  return data
})

export const postUserClassReservation = createAsyncThunk(
  '@@post/postUserClassReservation',
  async ({ user_id, params }) => {
    const response = await adminApi.postUserClassReservation(user_id, params)
    const { data } = response
    return data
  }
)

export const postUserAddress = createAsyncThunk(
  '@@post/postUserAddress',
  async ({ user_id, postal_code, line1, line2, city, state, address_type_id }) => {
    const response = await adminApi.postUserAddress(user_id, {
      postal_code,
      line1,
      line2,
      city,
      state,
      address_type_id,
    })
    const { data } = response
    return data
  }
)

export const postFitKit = createAsyncThunk('@@post/postFitKit', async ({ user_id, params }) => {
  const response = await adminApi.postUserFitKit(user_id, params)
  const { data } = response
  return data
})

export const postUserEmail = createAsyncThunk('@@post/postUserEmail', async ({ user_id, email, newEmail }) => {
  const response = await adminApi.postUserEmail(user_id, email, newEmail)
  const { data } = response
  return data
})

export const postUserEmailB2c = createAsyncThunk('@@post/postUserEmailB2c', async ({ user_id, email, newEmail }) => {
  const response = await adminApi.postUserEmailB2c(user_id, email, newEmail)
  const { data } = response
  return data
})

/**
 * Sponsors Actions
 */
export const fetchSponsors = createAsyncThunk('@@fetch/fetchSponsors', async (params) => {
  const response = await adminApi.getSponsors(params)
  const { data } = response
  return data
})

export const fetchEligibilites = createAsyncThunk('@@fetch/fetchEligibilites', async (params = {}) => {
  const response = await adminApi.getEligibilites(params)
  const { data } = response
  return data
})

export const postUserFromEligibility = createAsyncThunk('@@post/postUserFromEligibility', async (id) => {
  const response = await adminApi.postUserFromEligibility(id)
  const { data } = response
  return data
})

export const postSponsor = createAsyncThunk('@@post/postSponsor', async (params = {}) => {
  const response = await adminApi.postSponsor(params)
  const { data } = response
  return data
})

export const fetchSponsorById = createAsyncThunk('@@fetch/fetchSponsorById', async (id) => {
  const response = await adminApi.getSponsorById(id)
  const { data } = response
  return data
})

export const putSponsorById = createAsyncThunk('@@put/putSponsorById', async ({ id, params = {} }) => {
  const response = await adminApi.putSponsorById(id, params)
  const { data } = response
  return data
})

export const deleteSponsorById = createAsyncThunk('@@delete/deleteSponsorById', async (id) => {
  const response = await adminApi.deleteSponsorById(id)
  const { data } = response
  return data
})

export const postSponsorSubsidyTier = createAsyncThunk(
  '@@post/postSponsorSubsidyTier',
  async ({ sponsor_id, params }) => {
    const response = await adminApi.postSponsorSubsidyTier(sponsor_id, params)
    const { data } = response
    return data
  }
)

export const putSponsorSubsidyTierById = createAsyncThunk(
  '@@put/putSponsorSubsidyTierById',
  async ({ id, params = {} }) => {
    const response = await adminApi.putSponsorSubsidyTierById(id, params)
    const { data } = response
    return data
  }
)

export const putUploadSponsorImage = createAsyncThunk(
  '@@upload/putUploadSponsorImage',
  async ({ sponsor_id, params }) => {
    const response = await adminApi.putUploadSponsorImage(sponsor_id, params)
    const { data } = response
    return data
  }
)

export const deleteSponsorLogoImage = createAsyncThunk('@@delete/deleteSponsorLogoImage', async ({ sponsor_id }) => {
  const response = await adminApi.deleteSponsorLogoImage(sponsor_id)
  const { data } = response
  return data
})

export const postSponsorChallenge = createAsyncThunk(
  '@@post/postSponsorChallenge',
  async ({ sponsor_id, challenge_id }) => {
    const response = await adminApi.postSponsorChallenge({ sponsor_id, challenge_id })
    const { data } = response
    return data
  }
)

/**
 * Challenges Actions
 */
export const fetchChallenges = createAsyncThunk('@@fetch/fetchChallenges', async (params) => {
  const response = await adminApi.getChallenges(params)
  const { data } = response
  return data
})

export const postChallenge = createAsyncThunk('@@fetch/postChallenge', async (params) => {
  const response = await adminApi.postChallenge(params)
  const { data } = response
  return data
})

/**
 * Stripe Actions
 */
export const fetchStripeSubscriptions = createAsyncThunk('@@fetch/fetchStripeSubscriptions', async (params) => {
  const response = await adminApi.getStripeSubscriptions(params)
  const { data } = response
  return data
})

export const fetchUserStripeCards = createAsyncThunk('@@fetch/fetchUserStripeCards', async (params) => {
  const response = await adminApi.getStripeCards(params)
  const { data } = response
  return data
})

export const fetchUserFlexCards = createAsyncThunk('@@fetch/fetchUserFlexCards', async (params) => {
  const response = await adminApi.getFlexCards(params)
  const { data } = response
  return data
})

/**
 * Studios Actions
 */
export const fetchStudios = createAsyncThunk('@@fetch/fetchStudios', async (options) => {
  const { currentStudios, ...params } = options
  const response = await adminApi.getStudios(params)
  const { data } = response
  return !!currentStudios && data.results
    ? {
        ...data,
        results: [...currentStudios, ...data.results],
      }
    : data
})

export const postStudio = createAsyncThunk('@@post/postStudio', async (params) => {
  const response = await adminApi.postStudio(params)
  const { data } = response
  return data
})

export const fetchStudioById = createAsyncThunk('@@fetch/fetchStudioById', async (id) => {
  const response = await adminApi.getStudioById(id)
  const { data } = response
  return data
})

export const putStudioById = createAsyncThunk('@@put/putStudioById', async ({ studio_id, params }) => {
  const response = await adminApi.putStudioById(studio_id, params)
  const { data } = response
  return data
})

export const putUploadStudioImage = createAsyncThunk('@@upload/putUploadStudioImage', async ({ studio_id, params }) => {
  const response = await adminApi.putUploadStudioImage(studio_id, params)
  const { data } = response
  return data
})

export const deleteStudioLogoImage = createAsyncThunk('@@delete/deleteStudioLogoImage', async ({ studio_id }) => {
  const response = await adminApi.deleteStudioLogoImage(studio_id)
  const { data } = response
  return data
})

export const deleteStudioBannerImage = createAsyncThunk('@@delete/deleteStudioBannerImage', async ({ studio_id }) => {
  const response = await adminApi.deleteStudioBannerImage(studio_id)
  const { data } = response
  return data
})

export const fetchStudioMemberships = createAsyncThunk(
  '@@fetch/fetchStudioMemberships',
  async ({ studio_id, params }) => {
    const response = await adminApi.getStudioMemberships(studio_id, params)
    const { data } = response
    return data
  }
)

export const postStudioMembership = createAsyncThunk('@@post/postStudioMembership', async ({ studio_id, params }) => {
  const response = await adminApi.postStudioMembership(studio_id, params)
  const { data } = response
  return data
})

export const putStudioMembership = createAsyncThunk(
  '@@put/putStudioMembership',
  async ({ studio_id, plan_id, params }) => {
    const response = await adminApi.putStudioMembership({ studio_id, plan_id, params })
    const { data } = response
    return data
  }
)

export const deleteStudioMembership = createAsyncThunk(
  '@@delete/deleteStudioMembership',
  async ({ studio_id, plan_id }) => {
    const response = await adminApi.deleteStudioMembership({ studio_id, plan_id })
    const { data } = response
    return data
  }
)

export const fetchStudioClasses = createAsyncThunk('@@fetch/fetchStudioClasses', async ({ studio_id, params }) => {
  const response = await adminApi.getStudioClasses(studio_id, params)
  const { data } = response
  return data
})

export const postStudioClasses = createAsyncThunk('@@post/postStudioClasses', async ({ studio_id, params }) => {
  const response = await adminApi.postStudioClasses(studio_id, params)
  const { data } = response
  return data
})

export const putStudioClass = createAsyncThunk('@@put/putStudioClass', async ({ studio_id, class_id, params }) => {
  const response = await adminApi.putStudioClass(studio_id, class_id, params)
  const { data } = response
  return data
})

export const deleteStudioClass = createAsyncThunk('@@delete/deleteStudioClass', async ({ studio_id, class_id }) => {
  const response = await adminApi.deleteStudioClass({ studio_id, class_id })
  const { data } = response
  return data
})

export const fetchStudioContactUrls = createAsyncThunk(
  '@@fetch/fetchStudioContactUrls',
  async ({ studio_id, params }) => {
    const response = await adminApi.getStudioContactUrls(studio_id, params)
    const { data } = response
    return data
  }
)

export const fetchStudioContacts = createAsyncThunk('@@fetch/fetchStudioContacts', async ({ studio_id, params }) => {
  const response = await adminApi.getStudioContacts(studio_id, params)
  const { data } = response
  return data
})

export const postStudioContact = createAsyncThunk('@@post/postStudioContact', async ({ studio_id, params }) => {
  const response = await adminApi.postStudioContact(studio_id, params)
  const { data } = response
  return data
})

export const putStudioContact = createAsyncThunk(
  '@@put/putStudioContact',
  async ({ studio_id, contact_id, params }) => {
    const response = await adminApi.putStudioContact({ studio_id, contact_id, params })
    const { data } = response
    return data
  }
)

export const putStudioContactUrls = createAsyncThunk('@@put/putStudioContactUrls', async ({ studio_id, params }) => {
  const response = await adminApi.putStudioContactUrls({ studio_id, params })
  const { data } = response
  return data
})

export const putStudioAddress = createAsyncThunk('@@put/putStudioAddress', async ({ studio_id, params }) => {
  const response = await adminApi.putStudioAddress(studio_id, params)
  const { data } = response
  return data
})

export const deleteStudioContact = createAsyncThunk(
  '@@delete/deleteStudioContact',
  async ({ studio_id, contact_id }) => {
    const response = await adminApi.deleteStudioContact({ studio_id, contact_id })
    const { data } = response
    return data
  }
)

export const fetchStudioClassSessions = createAsyncThunk('@@fetch/fetchStudioClassSessions', async (params) => {
  const response = await adminApi.getStudioClassSessions(params)
  const { data } = response
  return data
})

export const fetchStudioEvents = createAsyncThunk('@@fetch/fetchStudioEvents', async ({ studio_id, params }) => {
  const response = await adminApi.getStudioEvents(studio_id, params)
  const { data } = response
  return data
})

export const postStudioEvent = createAsyncThunk('@@post/postStudioEvent', async ({ studio_id, params }) => {
  const response = await adminApi.postStudioEvent(studio_id, params)
  const { data } = response
  return data
})

export const putStudioEvent = createAsyncThunk('@@post/putStudioEvent', async ({ studio_id, event_id, params }) => {
  const response = await adminApi.putStudioEvent({ studio_id, event_id, params })
  const { data } = response
  return data
})

export const deleteStudioEvent = createAsyncThunk('@@delete/deleteStudioEvent', async ({ studio_id, event_id }) => {
  const response = await adminApi.deleteStudioEvent({ studio_id, event_id })
  const { data } = response
  return data
})

/**
 * Studio Groups Actions
 */
export const fetchStudioGroups = createAsyncThunk('@@fetch/fetchStudioGroups', async (params) => {
  const response = await adminApi.getStudioGroups(params)
  const { data } = response
  return data
})

export const postStudioGroup = createAsyncThunk('@@post/postStudioGroup', async (params) => {
  const response = await adminApi.postStudioGroup(params)
  const { data } = response
  return data
})

export const putStudioGroup = createAsyncThunk('@@put/putStudioGroup', async ({ id, params }) => {
  const response = await adminApi.putStudioGroup({ id, params })
  const { data } = response
  return data
})

export const deleteStudioGroup = createAsyncThunk('@@delete/deleteStudioGroup', async (id) => {
  const response = await adminApi.deleteStudioGroup(id)
  const { data } = response
  return data
})

export const fetchStudioGroupById = createAsyncThunk('@@fetch/fetchStudioGroupById', async (id) => {
  const response = await adminApi.getStudioGroupById(id)
  const { data } = response
  return data
})

export const fetchMboIntegrationsByStudioGroupId = createAsyncThunk(
  '@@fetch/fetchMboIntegrationsByStudioGroupId',
  async (id) => {
    const response = await adminApi.getStudioGroupMboIntegrations(id)
    const { data } = response

    return data
  }
)

export const fetchStudiosByStudioGroupId = createAsyncThunk(
  '@@fetch/fetchStudiosByStudioGroupId',
  async ({ id, page }) => {
    console.log({ id, page })
    const response = await adminApi.getStudioGroupStudios({ id, page })
    const { data } = response

    return data
  }
)

export const upsertPartner = createAsyncThunk('@@post/upsertPartner', async ({ params }) => {
  //console.log('arams', params)
  const response = await adminApi.upsertPartner(params)
  const { data } = response

  return data
})

/**
 * Mind Body Site Actions
 */
export const postMindBodySite = createAsyncThunk('@@post/postMindBodySite', async ({ id, params }) => {
  const response = await adminApi.postMindbodySite(id, params)
  const { data } = response

  return data
})

export const putMindBodySite = createAsyncThunk('@@post/putMindBodySite', async ({ id, params }) => {
  const response = await adminApi.putMindbodySite(id, params)
  const { data } = response

  return data
})

export const fetchSyncMBSiteByIdAndStudioGroupId = createAsyncThunk(
  '@@get/getSyncMBSiteByIdAndStudioGroupId',
  async (params, { rejectWithValue }) => {
    try {
      //console.log('params ', params)
      const response = await adminApi.getSyncMBSiteByIdAndStudioGroupId(params)
      const { data } = response
      return data
    } catch (err) {
      //console.log('Err: ', err)
      return rejectWithValue(err.response.data)
    }
  }
)

/**
 * Transactions Actions
 */
export const fetchTransactions = createAsyncThunk('@@fetch/fetchTransactions', async (params) => {
  const response = await adminApi.getTransactions(params)
  const { data } = response
  return data
})

export const fetchConsumptionTransactions = createAsyncThunk('@@fetch/fetchConsumptionTransactions', async (params) => {
  const response = await adminApi.getTransactions({ subtype: 2, ...params })
  const { data } = response
  return data
})

export const fetchTransactionById = createAsyncThunk('@@fetch/fetchTransactionById', async (id) => {
  const response = await adminApi.getTransactionById(id)
  const { data } = response
  return data
})

export const postTransactionSupply = createAsyncThunk('@@post/postTransactionSupply', async (params) => {
  try {
    const response = await adminApi.postTransactionSupply(params)
    const { data } = response
    return data
  } catch (error) {
    throw new Error(error?.response?.data?.Message || error)
  }
})

export const postTransactionAdjustment = createAsyncThunk(
  '@@post/postTransactionAdjustment',
  async ({ transaction_id, params }) => {
    const response = await adminApi.postTransactionAdjustment(transaction_id, params)
    const { data } = response
    return data
  }
)

/**
 * Subscriptions Actions
 */
export const fetchUserSubscriptions = createAsyncThunk('@@fetch/fetchUserSubscriptions', async (params) => {
  const response = await adminApi.getSubscriptions(params)
  const { data } = response
  return data
})

export const deleteSubscription = createAsyncThunk('@@delete/deleteSubscription', async (id) => {
  const response = await adminApi.deleteSubscription(id)``
  const { data } = response
  return data
})

/**
 * Reservations Actions
 */
export const fetchUserReservations = createAsyncThunk('@@fetch/fetchUserReservations', async (params) => {
  const response = await adminApi.getReservations(params)
  const { data } = response
  return data
})

export const fetchReservations = createAsyncThunk('@@fetch/fetchReservations', async (params) => {
  const response = await adminApi.getReservations(params)
  const { data } = response
  return data
})

export const fetchReservationById = createAsyncThunk('@@fetch/fetchReservationById', async (id) => {
  const response = await adminApi.getReservationsById(id)
  const { data } = response
  return data
})

export const postResendReservation = createAsyncThunk('@@post/postResendReservation', async (id) => {
  const response = await adminApi.postResendReservation(id)
  const { data } = response
  return data
})

export const deleteReservation = createAsyncThunk('@@delete/deleteReservation', async (id) => {
  const response = await adminApi.deleteReservation(id)
  const { data } = response
  return data
})

/**
 * Budget Actions
 */

export const fetchBudgets = createAsyncThunk('@@fetch/fetchBudgets', async (params) => {
  const response = await adminApi.getBudgets(params)
  const { data } = response
  return data
})

export const fetchBudgetById = createAsyncThunk('@@fetch/fetchBudgetById', async (id) => {
  const response = await adminApi.getBudgetById(id)
  const { data } = response
  return data
})

export const postBudget = createAsyncThunk('@@post/postBudget', async (params) => {
  const response = await adminApi.postBudget(params)
  const { data } = response
  return data
})

export const putBudget = createAsyncThunk('@@put/putBudget', async ({ id, params }) => {
  const response = await adminApi.putBudgetById(id, params)
  const { data } = response
  return data
})

export const deleteBudget = createAsyncThunk('@@delete/deleteBudget', async (id) => {
  const response = await adminApi.deleteBudget(id)
  const { data } = response
  return data
})

/**
 * Store Actions
 */
export const fetchStoreItems = createAsyncThunk('@@fetch/fetchStoreItems', async (params) => {
  const response = await adminApi.getStoreItems(params)
  const { data } = response
  return data
})

export const fetchAllStoreItems = createAsyncThunk('@@fetch/fetchStoreItems', async (params) => {
  const response = await adminApi.getAllStoreItems(params)
  const { data } = response
  return data
})

export const fetchStoreItemById = createAsyncThunk('@@fetch/fetchStoreItemById', async (id) => {
  const response = await adminApi.getStoreItemById(id)
  const { data } = response
  return data
})

export const postStoreItem = createAsyncThunk('@@post/postStoreItem', async (params = {}) => {
  const response = await adminApi.postStoreItem(params)
  const { data } = response
  return data
})

export const putStoreItemById = createAsyncThunk('@@put/putStoreItemById', async ({ id, params = {} }) => {
  const response = await adminApi.putStoreItemById(params)
  const { data } = response
  return data
})

export const deleteStoreItemById = createAsyncThunk('@@delete/deleteStoreItemById', async (id) => {
  const response = await adminApi.deleteStoreItemById(id)
  const { data } = response
  return data
})

/**
 * Enums
 */
export const fetchTransactionTypes = createAsyncThunk('@@fetch/fetchTransactionTypes', async (params) => {
  const response = await adminApi.getTransactionTypes(params)
  const { data } = response
  return data
})

export const fetchReservationTypes = createAsyncThunk('@@fetch/fetchReservationTypes', async (params) => {
  const response = await adminApi.getReservationTypes(params)
  const { data } = response
  return data
})

export const fetchSponsorTypes = createAsyncThunk('@@fetch/fetchSponsorTypes', async (params) => {
  const response = await adminApi.getSponsorTypes(params)
  const { data } = response
  return data
})

export const fetchAddressTypes = createAsyncThunk('@@fetch/fetchAddressTypes', async (params) => {
  const response = await adminApi.getAddressTypes(params)
  const { data } = response
  return data
})

export const fetchFitKitTypes = createAsyncThunk('@@fetch/fetchFitKitTypes', async (params) => {
  const response = await adminApi.getFitKitTypes(params)
  const { data } = response
  return data
})

export const fetchSupplyTransactionTypes = createAsyncThunk('@@fetch/fetchSupplyTransactionTypes', async (params) => {
  const response = await adminApi.getSupplyTransactionTypes(params)
  const { data } = response
  return data
})

export const fetchAdjustmentTransactionTypes = createAsyncThunk(
  '@@fetch/fetchAdjustmentTransactionTypes',
  async (params) => {
    const response = await adminApi.getAdjustmentTransactionTypes(params)
    const { data } = response
    return data
  }
)

export const fetchReservationStatusesType = createAsyncThunk('@@fetch/fetchReservationStatusesType', async (params) => {
  const response = await adminApi.getReservationStatusesTypes(params)
  const { data } = response
  return data
})

export const fetchTags = createAsyncThunk('@@fetch/fetchTags', async (params) => {
  const response = await adminApi.getTags(params)
  const { data } = response
  return data
})

export const fetchAmenities = createAsyncThunk('@@fetch/fetchAmenities', async (params) => {
  const response = await adminApi.getAmmentities(params)
  const { data } = response
  return data
})

export const fetchStudioEventActionTypes = createAsyncThunk('@@fetch/fetchStudioEventActionTypes', async (params) => {
  const response = await adminApi.getStudioEventActionTypes(params)
  const { data } = response
  return data
})

export const fetchChallengeTypes = createAsyncThunk('@@fetch/fetchChallengeTypes', async (params) => {
  const response = await adminApi.getChallengeTypes(params)
  const { data } = response
  return data
})

/**
 *  Promotions
 */

export const postPromotion = createAsyncThunk('@@post/postPromotion', async (params, { rejectWithValue }) => {
  try {
    const response = await adminApi.postPromotions(params)
    const { data } = response
    return data
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const fetchPromotions = createAsyncThunk('@@fetch/fetchPromotions', async (params) => {
  const response = await adminApi.getPromotions(params)
  //console.log('services.actions Response: ', response)
  const { data } = response
  return data
})

export const fetchPromotionById = createAsyncThunk('@@fetch/fetchPromotionById', async (id) => {
  const response = await adminApi.getPromotionById(id)
  const { data } = response
  return data
})

export const putPromotionById = createAsyncThunk('@@put/putPromotionById', async ({ id, params = {} }) => {
  const response = await adminApi.putPromotionById(id, params)
  const { data } = response
  return data
})

export const deletePromotion = createAsyncThunk('@@delete/deletePromotion', async (id) => {
  const response = await adminApi.deletePromotion(id)
  const { data } = response
  return data
})

export const fetchPromotionBenefit = createAsyncThunk('@@fetch/fetchPromotionBenefit', async (id) => {
  const response = await adminApi.getPromotionBenefit(id)
  const { data } = response
  return data
})

export const putPromotionBenefit = createAsyncThunk('@@put/putPromotionBenefit', async ({ id, params = {} }) => {
  const response = await adminApi.putPromotionBenefit(id, params)
  const { data } = response
  return data
})

export const fetchPromotionAuthorizedUsers = createAsyncThunk('@@fetch/fetchPromotionAuthorizedUsers', async (id) => {
  const response = await adminApi.getPromotionAuthorizedUsers(id)
  const { data } = response
  return data
})

/***
 * Consumer
 */
export const fetchConsumerPlans = createAsyncThunk('@@fetch/fetchConsumerPlans', async (params) => {
  const response = await adminApi.getConsumerPlans(params)
  const { data } = response
  return data
})

/**
 * Custom Actions
 */
export const addFitKit = createAsyncThunk('@@custom/addFitKit', async ({ user_id, params }, { dispatch }) => {
  const { storeItemId, postal_code, line1, line2, city, state, address_type_id, phone } = params
  const { payload: address } = await dispatch(
    postUserAddress({
      user_id,
      postal_code,
      line1,
      line2,
      city,
      state,
      address_type_id,
    })
  )

  try {
    const response = await adminApi.postUserFitKit(user_id, {
      userid: user_id,
      storeItemId,
      shippingaddressid: address?.id,
      phone,
    })
    const { data } = response
    return data
  } catch (error) {
    throw new Error(error?.response?.data?.Message || error)
  }
})

export const addSponsorChallenge = createAsyncThunk(
  '@@custom/addSponsorChallenge',
  async ({ sponsor_id, params }, { dispatch }) => {
    const { challenge_type_id, start_date, end_date } = params
    const { payload: challenge_id } = await dispatch(
      postChallenge({
        challenge_type_id,
        start_date,
        end_date,
      })
    )

    if (challenge_id) {
      const { id } = challenge_id
      const response = await adminApi.postSponsorChallenge({ sponsor_id, challenge_id: id })
      const { data } = response
      return data
    }

    throw new Error('No challenge_id')
  }
)
