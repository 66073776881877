import React from 'react'
import { isEmpty } from 'lodash'

import { ScrollableModal, DataCardRows } from 'components'

const QuickViewModal = ({ data, rows, open, onClose, renderHeader, renderActions, ...props }) => {
  const hasData = !isEmpty(data)

  return (
    <ScrollableModal width={800} open={open} onClose={onClose} renderHeader={renderHeader} renderFooter={renderActions}>
      {hasData && <DataCardRows rows={rows} data={data} labelWidth={200} hover />}
    </ScrollableModal>
  )
}

export default QuickViewModal
