import { createSlice } from '@reduxjs/toolkit'

import { fetchStripeSubscriptions } from 'store/actions/services.actions'

const stripeSlice = createSlice({
  name: 'stripe',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    stripeSubscriptions: {},
  },
  reducers: {},
  extraReducers: {
    [`${fetchStripeSubscriptions.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),

    [`${fetchStripeSubscriptions.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),

    [`${fetchStripeSubscriptions.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, stripeSubscriptions: { ...action.payload } }
    },
  },
})

export default stripeSlice.reducer
