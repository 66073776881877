import { format } from 'date-fns'

const DATE_FORMAT = 'MM/dd/yyyy'

const getFormattedDate = (iso) => {
  return iso ? format(new Date(iso), DATE_FORMAT) : '—'
}

export const getISOStringWithoutTimezoneOffset = (iosString) => {
  let date = new Date(iosString)
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - offset * 60 * 1000)
  return date.toISOString().split('T')[0] + `T00:00:00`
}

export default getFormattedDate
