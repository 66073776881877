import { createSlice } from '@reduxjs/toolkit'

import { fetchReservations, fetchReservationById } from 'store/actions/services.actions'

const reservationsSlice = createSlice({
  name: 'reservations',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    reservation: null,
    reservations: {
      isLoading: false,
      status: null,
      errors: null,
    },
  },
  reducers: {
    clearReservation: (state) => ({ ...state, reservation: null }),
    clearReservations: (state) => ({ ...state, reservations: { isLoading: false, status: null, errors: null } }),
  },
  extraReducers: {
    [`${fetchReservations.pending}`]: (state, action) => ({
      ...state,
      reservations: { ...state.reservations, isLoading: true, errors: null },
    }),
    [`${fetchReservationById.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),

    [`${fetchReservations.rejected}`]: (state, action) => ({
      ...state,
      reservations: {
        ...state.reservations,
        isLoading: false,
        errors: action?.error?.message,
      },
    }),
    [`${fetchReservationById.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
      reservation: null,
    }),

    [`${fetchReservations.fulfilled}`]: (state, action) => {
      return { ...state, reservations: { ...state.reservations, isLoading: false, errors: null, ...action.payload } }
    },
    [`${fetchReservationById.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, errors: null, reservation: { ...action.payload } }
    },
  },
})

export const reservationsActions = reservationsSlice.actions

export default reservationsSlice.reducer
