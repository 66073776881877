import { createSlice } from '@reduxjs/toolkit'

import { fetchTransactions, fetchConsumptionTransactions, fetchTransactionById } from 'store/actions/services.actions'

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    transaction: null,
    transactions: {
      isLoading: false,
      status: null,
      errors: null,
    },
    consumptionTransactions: {
      isLoading: false,
      status: null,
      errors: null,
    },
  },
  reducers: {
    clearTransaction: (state) => ({ ...state, transaction: null }),
    clearTransactions: (state) => ({
      ...state,
      transactions: {
        isLoading: false,
        status: null,
        errors: null,
      },
    }),
    clearConsumptionTransactions: (state) => ({
      ...state,
      consumptionTransactions: {
        isLoading: false,
        status: null,
        errors: null,
      },
    }),
  },
  extraReducers: {
    [`${fetchTransactions.pending}`]: (state, action) => ({
      ...state,
      transactions: { isLoading: true, errors: null },
    }),
    [`${fetchConsumptionTransactions.pending}`]: (state, action) => ({
      ...state,
      consumptionTransactions: { isLoading: true, errors: null },
    }),
    [`${fetchTransactionById.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),

    [`${fetchTransactions.rejected}`]: (state, action) => ({
      ...state,
      transactions: { ...state.transactions, isLoading: false, errors: action?.error?.message },
    }),
    [`${fetchConsumptionTransactions.rejected}`]: (state, action) => ({
      ...state,
      consumptionTransactions: { ...state.consumptionTransactions, isLoading: false, errors: action?.error?.message },
    }),
    [`${fetchTransactionById.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),

    [`${fetchTransactions.fulfilled}`]: (state, action) => {
      return { ...state, transactions: { ...state.transactions, isLoading: false, ...action.payload } }
    },
    [`${fetchConsumptionTransactions.fulfilled}`]: (state, action) => {
      return {
        ...state,
        consumptionTransactions: { ...state.consumptionTransactions, isLoading: false, ...action.payload },
      }
    },
    [`${fetchTransactionById.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, transaction: { ...action.payload } }
    },
  },
})

export const transactionsActions = transactionsSlice.actions

export default transactionsSlice.reducer
