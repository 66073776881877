import { useLocation, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

export const useQuery = () => {
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const setQuery = (params = {}) => {
    Object.entries(params).forEach(([key, value]) => {
      value === false || isEmpty(`${value}`) ? query.delete(key) : query.set(key, `${value}`)
    })
    history.push({ search: query.toString() })
  }
  const clearQuery = () => {
    history.push({ search: new URLSearchParams('').toString() })
  }
  return [query, setQuery, clearQuery]
}
