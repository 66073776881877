import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    account: {},
    accessToken: null,
    isAuthenticated: false,
  },
  reducers: {
    setAccessToken: (state, action) => ({ ...state, accessToken: action.payload }),
    setAccount: (state, action) => ({ ...state, account: action.payload }),
    setIsAuthenticated: (state, action) => ({ ...state, isAuthenticated: action.payload }),
  },
  extraReducers: {},
})

export const authActions = authSlice.actions

export default authSlice.reducer
