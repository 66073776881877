import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Divider, IconButton, Modal, Paper, makeStyles } from '@material-ui/core'
import { X as CloseXIcon } from 'react-feather'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
  },
  container: {
    maxWidth: 'calc(100vw - 40px)',
    outline: 0,
  },
  content: {
    height: (props) => props.height || 'calc(100vh - 300px)',
    maxHeight: 'calc(100vh - 300px)',
    width: (props) => props.width || '100%',
    position: 'relative',
    overflow: 'hidden',
  },
}))

const ScrollableModal = React.forwardRef(
  (
    { className, width, height, renderHeader, renderFooter, open, onClose, children, footerBorder = true, ...props },
    ref
  ) => {
    const classes = useStyles({ width, height })

    return (
      <Modal ref={ref} className={clsx(classes.root, className)} open={open} onClose={onClose} {...props}>
        <Paper className={classes.container} elevation={3}>
          <Box p={2} ml={1} display="flex" justifyContent="space-between" alignItems="center">
            {renderHeader && renderHeader()}
            <IconButton onClick={onClose}>
              <CloseXIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box className={classes.content}>
            <PerfectScrollbar options={{ suppressScrollX: true }}>
              <Box p={2}>{children}</Box>
            </PerfectScrollbar>
          </Box>
          {footerBorder && <Divider />}

          <Box p={2} ml={1} mr={1} mb={1} display="flex" justifyContent="flex-end" alignItems="center">
            {renderFooter && renderFooter()}
          </Box>
        </Paper>
      </Modal>
    )
  }
)

ScrollableModal.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

export default ScrollableModal
