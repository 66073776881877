import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isEmpty, debounce } from 'lodash'

import { fetchBrands } from 'store/actions/services.actions'
import { AutoCompleteBox } from 'helpers/forms'

export const BrandsAutocompleteBox = React.memo(({ control, errors, onValueChange, ...props }) => {
  const {
    brands: { isLoading },
    brands,
  } = useSelector((state) => state.services.brands)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const { required } = { ...props }

  const options = React.useMemo(
    () => (isEmpty(brands?.results) ? [] : brands.results.map((item) => ({ ...item, name: item.name }))),
    [brands]
  )

  const handleOpen = () => {
    isEmpty(brands?.results) && dispatch(fetchBrands())
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleResultsChange = debounce((results, value) => {
    results.length < 2 && !isLoading && dispatch(fetchBrands({ searchq: value }))
  }, 300)

  return (
    <AutoCompleteBox
      control={control}
      errors={errors}
      name="brand"
      label={t('common.form_labels.brand')}
      options={options}
      onValueChange={onValueChange}
      onResultsChange={handleResultsChange}
      onOpen={handleOpen}
      onClose={handleClose}
      isLoading={isLoading}
      open={open}
      {...(required && { required })}
    />
  )
})
