import React, { useState, useEffect } from 'react'
import { Tabs, Tab, Box, Divider } from '@material-ui/core'

import { useQuery } from 'hooks/useQuery'

const getTabIndexByLabel = (tabs, label) => {
  const tabIndex = tabs.findIndex((tab) => tab === label)
  return tabIndex > -1 ? tabIndex : 0
}

const TabsView = ({ tabs, children, ...props }) => {
  const [query, setQuery] = useQuery()
  const tab = query.get('tab')
  const [currentTabIndex, setCurrentTabIndex] = useState(getTabIndexByLabel(tabs, tab))

  const handleTabsChange = (event, value) => {
    const label = event?.target?.textContent
    setQuery({ tab: label })
  }

  useEffect(() => {
    setCurrentTabIndex(getTabIndexByLabel(tabs, tab))
  }, [tabs, tab])

  return (
    <Box>
      <Box mt={3}>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={currentTabIndex}
          variant="scrollable"
        >
          {tabs.map((tab, index) => (
            <Tab key={tab} label={tab} value={index} />
          ))}
        </Tabs>
      </Box>
      <Divider />
      <Box mt={3}>{React.Children.toArray(children).filter((child, index) => index === currentTabIndex)}</Box>
    </Box>
  )
}

export default React.memo(TabsView)
