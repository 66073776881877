import { createSlice } from '@reduxjs/toolkit'

import {
  fetchPromotionById,
  fetchPromotions,
  fetchPromotionBenefit,
  fetchPromotionAuthorizedUsers,
  postPromotion,
} from 'store/actions/services.actions'

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    promotion: null,
    promotions: {
      isLoading: false,
      status: null,
      errors: null,
    },
    benefit: {
      isLoading: false,
      status: null,
      errors: null,
    },
    authorizedUsers: {
      isLoading: false,
      status: null,
      errors: null,
    },
  },
  reducers: {
    clearPromotion: (state) => ({ ...state, promotion: null, benefit: null }),
    clearPromotions: (state) => ({
      ...state,
      promotions: {
        isLoading: false,
        status: null,
        errors: null,
      },
    }),
    clearAuthorizedUsers: (state) => ({
      ...state,
      authorizedUsers: {
        isLoading: false,
        status: null,
        errors: null,
      },
    }),
  },
  extraReducers: {
    /**
     * Pending
     */
    [`${fetchPromotions.pending}`]: (state, action) => ({
      ...state,
      promotions: {
        ...state.promotions,
        isLoading: true,
        status: null,
        errors: null,
      },
    }),
    [`${fetchPromotionById.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchPromotionBenefit.pending}`]: (state, action) => ({ ...state, benefit: { isLoading: true, errors: null } }),
    [`${fetchPromotionAuthorizedUsers.pending}`]: (state, action) => ({
      ...state,
      authorizedUsers: { isLoading: true, errors: null },
    }),

    /**
     * Rejected
     */
    [`${fetchPromotions.rejected}`]: (state, action) => ({
      ...state,
      promotions: {
        ...state.promotions,
        isLoading: false,
        status: null,
        errors: action?.error?.message,
      },
    }),
    [`${fetchPromotionById.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchPromotionBenefit.rejected}`]: (state, action) => ({
      ...state,
      benefit: { isLoading: false, errors: action?.error?.message },
    }),
    [`${fetchPromotionAuthorizedUsers.rejected}`]: (state, action) => ({
      ...state,
      authorizedUsers: { isLoading: false, errors: action?.error?.message },
    }),
    [`${postPromotion.rejected}`]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        //status: STATUS.FAILED,
        errors: action?.error?.message,
      }
    },

    /**
     * Fulfilled
     */
    [`${fetchPromotions.fulfilled}`]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        promotions: { ...state.promotions, isLoading: false, status: null, errors: null, ...action.payload },
      }
    },
    [`${fetchPromotionById.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, promotion: { ...action.payload } }
    },
    [`${fetchPromotionBenefit.fulfilled}`]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        benefit: { ...state.benefit, isLoading: false, status: null, errors: null, ...action.payload },
      }
    },
    [`${fetchPromotionAuthorizedUsers.fulfilled}`]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        authorizedUsers: { ...state.authorizedUsers, isLoading: false, status: null, errors: null, ...action.payload },
      }
    },
  },
})

export const promotionsActions = promotionsSlice.actions

export default promotionsSlice.reducer
