import { createSlice } from '@reduxjs/toolkit'

import {
  fetchStudioGroups,
  fetchStudioGroupById,
  fetchMboIntegrationsByStudioGroupId,
  fetchStudiosByStudioGroupId,
} from 'store/actions/services.actions'

const studioGroupsSlice = createSlice({
  name: 'studioGroups',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    studioGroup: null,
    studioGroups: {
      isLoading: false,
      status: null,
      errors: null,
    },
  },
  reducers: {
    clearStudioGroup: (state) => ({ ...state, studioGroup: null }),
    clearStudioGroups: (state) => ({
      ...state,
      studioGroups: {
        isLoading: false,
        status: null,
        errors: null,
      },
    }),
  },
  extraReducers: {
    [`${fetchStudioGroups.pending}`]: (state, action) => ({
      ...state,
      studioGroups: { ...state.studioGroups, isLoading: true, errors: null },
    }),
    [`${fetchStudioGroupById.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),

    [`${fetchStudioGroups.rejected}`]: (state, action) => ({
      ...state,
      studioGroups: { ...state.studioGroups, isLoading: false, errors: action?.error?.message },
    }),
    [`${fetchStudioGroupById.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),

    [`${fetchStudioGroups.fulfilled}`]: (state, action) => {
      return {
        ...state,
        studioGroups: { ...state.studioGroups, isLoading: false, errors: false, ...action.payload },
      }
    },
    [`${fetchStudioGroupById.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, studioGroup: { ...action.payload } }
    },
    [`${fetchMboIntegrationsByStudioGroupId.pending}`]: (state, action) => ({
      ...state,
      isLoading: true,
      errors: null,
    }),
    [`${fetchMboIntegrationsByStudioGroupId.fulfilled}`]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        studioGroup: { ...state.studioGroup, mboIntegrations: [...action.payload] },
      }
    },
    [`${fetchMboIntegrationsByStudioGroupId.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    // [`${fetchSyncMBSiteByIdAndStudioGroupId.rejected}`]: (state, action) => ({
    //   ...state,
    //   isLoading: false,
    //   errors: action?.error?.message,
    // }),
    [`${fetchStudiosByStudioGroupId.pending}`]: (state, action) => ({
      ...state,
      isLoading: true,
      errors: null,
    }),
    [`${fetchStudiosByStudioGroupId.fulfilled}`]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        studioGroup: { ...state.studioGroup, studiosInfo: action.payload },
      }
    },
    [`${fetchStudiosByStudioGroupId.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
  },
})

export const studioGroupsActions = studioGroupsSlice.actions

export default studioGroupsSlice.reducer
