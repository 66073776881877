import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const ConfirmationDialog = ({
  title,
  message,
  open,
  onClose,
  onConfirm,
  okText,
  okButtonProps,
  cancelText,
  hideCancel,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box sx={{ fontSize: 20 }}>{title}</Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {!hideCancel && (
          <Button onClick={onClose} color="primary">
            {cancelText ?? t('common.no')}
          </Button>
        )}

        <Button {...(okButtonProps ?? {})} onClick={onConfirm} color="primary">
          {okText ?? t('common.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
