import { createSlice } from '@reduxjs/toolkit'
import { fetchEligibilites, fetchSponsorById, fetchSponsors } from 'store/actions/services.actions'

const initialState = {
  isLoading: false,
  status: null,
  errors: null,
  sponsor: null,
  sponsors: {
    isLoading: false,
    status: null,
    errors: null,
  },
  eligibilites: {
    isLoading: false,
    status: null,
    errors: null,
  },
}

const sponsorEligibilitesExtraReducers = {
  [`${fetchEligibilites.pending}`]: (state, action) => ({
    ...state,
    eligibilites: { isLoading: true, errors: null },
  }),
  [`${fetchEligibilites.rejected}`]: (state, action) => ({
    ...state,
    eligibilites: { isLoading: false, errors: action?.error?.message },
  }),
  [`${fetchEligibilites.fulfilled}`]: (state, action) => {
    // console.log('Eligibilites Loaded')
    return { ...state, eligibilites: { isLoading: false, errors: null, ...action.payload } }
  },
}

const sponserExtraReducers = {
  [`${fetchSponsors.pending}`]: (state, action) => ({
    ...state,
    sponsors: {
      ...state.sponsors,
      isLoading: true,
      status: null,
      errors: null,
    },
  }),
  [`${fetchSponsorById.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),

  [`${fetchSponsors.rejected}`]: (state, action) => ({
    ...state,
    sponsors: {
      ...state.sponsors,
      isLoading: false,
      status: null,
      errors: action?.error?.message,
    },
  }),
  [`${fetchSponsorById.rejected}`]: (state, action) => ({
    ...state,
    isLoading: false,
    errors: action?.error?.message,
  }),

  [`${fetchSponsors.fulfilled}`]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      sponsors: { ...state.sponsors, isLoading: false, status: null, errors: null, ...action.payload },
    }
  },
  [`${fetchSponsorById.fulfilled}`]: (state, action) => {
    return { ...state, isLoading: false, sponsor: { ...action.payload } }
  },
}

const sponsorsSlice = createSlice({
  name: 'sponsors',
  initialState,
  reducers: {
    clearSponsor: (state) => ({ ...state, sponsor: null }),
    clearSponsors: (state) => ({ ...state, sponsors: { isLoading: false, status: null, errors: null } }),
  },
  extraReducers: {
    ...sponsorEligibilitesExtraReducers,
    ...sponserExtraReducers,
  },
})

export const sponsorsActions = sponsorsSlice.actions

export default sponsorsSlice.reducer
