import { useAccount } from '@azure/msal-react'
import { useEffect, useState } from 'react'

export const GroupIds = {
  PFAdminProductionTeam: 'cae5da4f-a8ae-4b83-b135-7fcce02f48eb',
  PFAdminMetovaDevs: '11549b4c-b458-4864-bfbf-cc0838c8c57a',
  PFAdminiLabsDevs: '680a0129-50ed-4d0d-9cea-5d2f961fa933',
  PFAdminQA: '71864862-cb72-4b82-bced-d107a8e77fb5',
}

const permissionConfig = {
  featureFlags: [
    GroupIds.PFAdminMetovaDevs,
    GroupIds.PFAdminiLabsDevs,
    GroupIds.PFAdminQA,
    GroupIds.PFAdminProductionTeam,
  ],
  storeItems: [
    GroupIds.PFAdminMetovaDevs,
    GroupIds.PFAdminiLabsDevs,
    GroupIds.PFAdminQA,
    GroupIds.PFAdminProductionTeam,
  ],
}

export const usePermission = () => {
  const [canAccess, setCanAccess] = useState({})
  const account = useAccount()
  useEffect(() => {
    setCanAccess(
      Object.keys(permissionConfig).reduce((acc, key) => {
        acc[key] = account?.idTokenClaims?.groups?.some((groupId) => permissionConfig[key].includes(groupId))
        return acc
      }, {})
    )
  }, [account])
  return canAccess
}
