import React from 'react'
import { isEmpty, isFunction } from 'lodash'
import { Box, TableContainer, Table, TableBody, TableRow, TableCell, Typography, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { GetFormattedByType } from 'helpers/getFormattedByType'
import SortHeader from './SortHeader'
import { ScaleLoader } from 'react-spinners'

export const TableRowEmpty = ({ colSpan, message, ...props }) => {
  const { t } = useTranslation()
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        <Box p={8}>
          <Typography variant="body2" color="textSecondary">
            {message || t('common.messages.no_results')}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export const TableRowLoader = ({ colSpan, ...props }) => {
  const theme = useTheme()
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        <Box py={12}>
          <ScaleLoader height={40} width={8} radius={10} margin={4} color={theme.palette.primary.main} />
        </Box>
      </TableCell>
    </TableRow>
  )
}

export const DataTableRows = ({ data, columns, isLoading, renderActions, ...props }) => {
  const colSpan = renderActions ? columns.length + 1 : columns.length

  return (
    <TableBody>
      {isLoading ? (
        <TableRowLoader colSpan={colSpan} />
      ) : isEmpty(data) ? (
        <TableRowEmpty colSpan={colSpan} />
      ) : (
        data.map((item, index) => {
          return (
            <TableRow hover key={item?.id || index}>
              {!isEmpty(columns) &&
                columns.map((col) => (
                  <TableCell key={`${col.id}-${index}`} align={col?.align || 'left'} width={col?.width}>
                    <GetFormattedByType item={item} col={col} />
                  </TableCell>
                ))}
              {isFunction(renderActions) && <TableCell align="right">{renderActions(item, index)}</TableCell>}
            </TableRow>
          )
        })
      )}
    </TableBody>
  )
}

const DataTable = ({ data, order, sortBy, columns, onSort, children, isLoading, hasActions, renderActions }) => {
  return (
    <TableContainer>
      <Table>
        <SortHeader
          order={order}
          sortBy={sortBy}
          columns={columns}
          onSort={onSort}
          hasActions={hasActions || renderActions}
        />
        <DataTableRows data={data} columns={columns} isLoading={isLoading} renderActions={renderActions} />
        {children}
      </Table>
    </TableContainer>
  )
}

export default DataTable
