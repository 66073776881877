import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { fetchAmenities } from 'store/actions/services.actions'
import { AutoCompleteBox } from 'helpers/forms'

export const AmenityAutocompleteBox = React.memo(
  ({ user, control, name, label, errors, required, onValueChange, ...props }) => {
    const { isLoading, amenities } = useSelector((state) => state.enums)

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)

    const options = React.useMemo(
      () => (isEmpty(amenities) ? [] : amenities.filter((item) => !isEmpty(item?.name))),
      [amenities]
    )
    const handleOpen = () => {
      isEmpty(amenities) && dispatch(fetchAmenities())

      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }

    return (
      <AutoCompleteBox
        control={control}
        errors={errors}
        name={name}
        label={label || t('common.form_labels.amenities')}
        isLoading={isLoading}
        required={required}
        options={options}
        onValueChange={onValueChange}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        multiple
      />
    )
  }
)
