import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication, EventType } from '@azure/msal-browser'
// import { ConfidentialClientApplication } from '@azure/msal-node'

import { msalConfig, loginRequest } from 'authConfig'
import { authActions } from 'store/reducers/auth.reducer'
import { isEmpty } from 'lodash'

export const msalInstance = new PublicClientApplication(msalConfig)
//export const msalApp = new ConfidentialClientApplication(msalConfig);

export const logOut = () => {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    msalInstance.logoutRedirect()
    return
  }
  msalInstance.logoutRedirect()
  return

  // const accounts = msalApp.getTokenCache().getAllAccounts();
  // // filter on the account that you want to delete from the cache.
  // // I take the first one here to keep the code sample short
  // const account = accounts[0];
  // msalApp.getTokenCache().removeAccount(account);
}

export const getAccessToken = () => {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    msalInstance.loginRedirect()
    return
  }
  return msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  })
}

export const AuthProvider = ({ children, ...props }) => {
  const { isAuthenticated } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const accounts = msalInstance.getAllAccounts()

  useEffect(() => {
    if (accounts.length) {
      const [account] = accounts
      if (account) {
        msalInstance.setActiveAccount(account)
        !isAuthenticated && dispatch(authActions.setIsAuthenticated(true))
      }
    }
  }, [dispatch, accounts, isAuthenticated])

  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((event) => {
      switch (event.eventType) {
        case EventType.LOGIN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
          if (!isEmpty(event.payload?.account)) {
            const { accessToken, account } = event.payload
            msalInstance.setActiveAccount(account)
            dispatch(authActions.setAccessToken(accessToken))
            dispatch(authActions.setAccount(account))
            !isAuthenticated && dispatch(authActions.setIsAuthenticated(true))
          }
          break
        default:
          break
      }
    })

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId)
      }
    }
  }, [dispatch, isAuthenticated])

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}
