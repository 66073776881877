import { createSlice } from '@reduxjs/toolkit'

import { fetchChallenges } from 'store/actions/services.actions'

const challengesSlice = createSlice({
  name: 'challenges',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    challenges: {
      isLoading: false,
      status: null,
      errors: null,
    },
  },
  reducers: {
    clearChallenges: (state) => ({
      ...state,
      challenges: {
        isLoading: false,
        status: null,
        errors: null,
      },
    }),
  },
  extraReducers: {
    [`${fetchChallenges.pending}`]: (state, action) => ({ ...state, challenges: { isLoading: true, errors: null } }),

    [`${fetchChallenges.rejected}`]: (state, action) => ({
      ...state,
      challenges: { ...state.challenges, isLoading: false, errors: action?.error?.message },
    }),

    [`${fetchChallenges.fulfilled}`]: (state, action) => {
      return { ...state, challenges: { ...state.challenges, isLoading: false, errors: null, ...action.payload } }
    },
  },
})

export const challengesActions = challengesSlice.actions

export default challengesSlice.reducer
