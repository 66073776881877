import { createSlice } from '@reduxjs/toolkit'

import { fetchRegions, fetchRegionById } from 'store/actions/services.actions'

const regionsSlice = createSlice({
  name: 'regions',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    region: null,
    regions: {},
  },
  reducers: {
    clearRegion: (state) => ({ ...state, region: null }),
  },
  extraReducers: {
    [`${fetchRegions.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchRegionById.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),

    [`${fetchRegions.rejected}`]: (state, action) => ({ ...state, isLoading: false, errors: action?.error?.message }),
    [`${fetchRegionById.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),

    [`${fetchRegions.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, regions: { ...action.payload } }
    },
    [`${fetchRegionById.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, region: { ...action.payload } }
    },
  },
})

export const regionsActions = regionsSlice.actions

export default regionsSlice.reducer
