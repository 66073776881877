import React, { useEffect } from 'react'
import { SelectInputBox } from 'helpers/forms'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTransactionTypes } from 'store/actions/services.actions'

const TransactionType = ({ control, name, required }) => {
  const { transactionTypes } = useSelector((state) => state.enums)
  const dispatch = useDispatch()
  const transactionTypesOptions = React.useMemo(() => {
    return isEmpty(transactionTypes) ? [] : transactionTypes.map((type) => ({ label: type.name, value: type.id }))
  }, [transactionTypes])
  useEffect(() => {
    isEmpty(transactionTypes) && dispatch(fetchTransactionTypes())
  }, [dispatch, transactionTypes])
  return (
    <SelectInputBox
      label="TransactionType"
      required={required}
      options={transactionTypesOptions}
      control={control}
      name={name}
    />
  )
}

export default TransactionType
