const { REACT_APP_AUTH_CLIENT_ID, REACT_APP_AUTH_TENANT_ID, REACT_APP_PF_ENDPOINT_URL, REACT_APP_API_CLIENT_ID } =
  process.env

export const AUTH_CLIENT_ID = REACT_APP_AUTH_CLIENT_ID
export const AUTH_TENANT_ID = REACT_APP_AUTH_TENANT_ID

export const API_CLIENT_ID = REACT_APP_API_CLIENT_ID
export const PF_ENDPOINT_URL = REACT_APP_PF_ENDPOINT_URL

export const RoutePaths = {
  NOT_FOUNDED: '/404',
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  DASHBOARD: '/dashboard',
  USERS: '/users',
  USERS_DETAILS: '/users/:id',
  BRANDS: '/brands',
  BRANDS_DETAILS: '/brands/:id',
  BUDGETS: '/budgets',
  BUDGET_DETAILS: '/budgets/:id',
  FEATURE_FLAGS: '/feature-flags',
  FEATURE_FLAG_DETAIL: '/feature-flags/:id',
  REGIONS: '/regions',
  REGIONS_DETAILS: '/regions/:id',
  RESERVATIONS: '/reservations',
  RESERVATIONS_DETAILS: '/reservations/:id',
  SPONSORS: '/sponsors',
  SPONSORS_DETAILS: '/sponsors/:id',
  STUDIOS: '/studios',
  STUDIOS_DETAILS: '/studios/:id',
  STUDIO_GROUPS: '/studio-groups',
  STUDIO_GROUPS_DETAILS: '/studio-groups/:id',
  STORE_ITEMS: '/store-items',
  STORE_ITEM_DETAIL: '/store-items/:id',
  PROMOCODES: '/promotions',
  PROMOCODES_DETAILS: '/promotions/:id',
  TRANSACTIONS: '/transactions',
  TRANSACTIONS_DETAILS: '/transactions/:id',
  ACCOUNT: '/account',
  MBO_SITE_DETAILS: '/mb-sites/:siteid',
  STUDIOS_BULK_UPLOAD: '/studios/bulk-upload',
  REACTIVATE_MEMBERS: '/users/reactivate',
}
