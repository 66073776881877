import { useTheme } from '@material-ui/core'
import { ScaleLoader } from 'react-spinners'

const Loader = () => {
  const theme = useTheme()

  return <ScaleLoader height={60} width={10} radius={10} margin={6} color={theme.palette.primary.main} />
}

export default Loader
